import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import text from '../../_constants/text'
import AlfredMaterImagingWrapper from './AlfredMaterImagingWrapper'
import AlfredMaterImagingForm from './AlfredMaterImagingForm'
import {
  AlfredMaterImagingFormDataModel,
  AlfredMaterImagingFormErrorModel,
} from '../../reducers/AlfredMaterImagingReducer/AlfredMaterImagingReducer'
import SubmitAlfredMaterImagingSuccess from './Success'
import routes from '../../router/routes'

interface AlfredMaterImagingPageProps {
  submissionSuccess: boolean
  submissionFailed: boolean
  formData: AlfredMaterImagingFormDataModel
  errors: AlfredMaterImagingFormErrorModel
  onValueChange: (fieldName: string, value: string | boolean) => void
  onSubmit: () => void
  clearForm: () => void
}

const AlfredMaterImagingPage: FC<AlfredMaterImagingPageProps> = ({
  submissionSuccess,
  submissionFailed,
  formData,
  errors,
  onValueChange,
  onSubmit,
  clearForm,
}) => {
  const heading = submissionSuccess ? text.REGISTRATION_SENT : text.ALFRED_MATER_IMAGING_REGISTRATION_FORM_TITLE
  return (
    <div
      data-testid="alfred-mater-imaging-page"
      className="d-flex content-background content-background-min-height pb-5"
    >
      <Container className="max-grid-width pb-2 content-padding" fluid>
        <PageHeadingRow title={heading} isSuccess={submissionSuccess} />
        <Row className="px-0 flex-column-reverse flex-lg-row">
          <Col xs={12} lg={8}>
            {submissionSuccess ? (
              <SubmitAlfredMaterImagingSuccess
                messageOne={text.ALFRED_MATER_IMAGING_SUCCESS_MESSAGE_1}
                messageTwo={text.ALFRED_MATER_IMAGING_SUCCESS_MESSAGE_2}
                clearForm={clearForm}
                buttonText={text.BACK_TO_HOME}
                linkRoute={routes.home}
              />
            ) : (
              <AlfredMaterImagingWrapper
                submitRegistration={onSubmit}
                submissionFail={submissionFailed}
                buttonText={text.SEND_REGISTRATION}
                errorMessage={text.REFERRAL_SUBMIT_ERROR}
              >
                <AlfredMaterImagingForm formData={formData} errors={errors} onValueChange={onValueChange} />
              </AlfredMaterImagingWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AlfredMaterImagingPage

import React, { FC } from 'react'
import Card from 'react-bootstrap/Card'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface SubmitAlfredMaterImagingSuccessProps {
  messageOne: string
  messageTwo: string
  clearForm: () => void
  buttonText: string
  linkRoute: string
}

const SubmitAlfredMaterImagingSuccess: FC<SubmitAlfredMaterImagingSuccessProps> = ({
  messageOne,
  messageTwo,
  clearForm,
  buttonText,
  linkRoute,
}) => {
  return (
    <Card as="section" className="my-3 my-lg-4" data-testid="submit-alfred-mater-imaging-success">
      <Card.Body className="p-3 p-lg-4">
        <Card.Text as="div" data-testid="subtitle" className="mt-3 success-registration">
          <p data-testid="alfred-mater-imaging-success-message-1">{messageOne}</p>
          <p data-testid="alfred-mater-imaging-success-message-2">{messageTwo}</p>
        </Card.Text>
        <Link to={linkRoute}>
          <Button data-testid="back-to-search-button" className="mt-3" onClick={clearForm}>
            {buttonText}
          </Button>
        </Link>
      </Card.Body>
    </Card>
  )
}

export default SubmitAlfredMaterImagingSuccess
